<template>
  <div v-if="$canAndRulesPass(user, 'setting.back_integration')">
    <page-heading
      heading="Результаты интеграции"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>


    <div style="width: 50%">
      <el-table
        style="width: 100%"
        :data="integrations"
        empty-text="Нет истории"
        v-loading="loading"
        size="mini"
      >
        <el-table-column prop="created_at" label="Дата запуска" align="center">
          <template slot-scope="scope">
            {{ new Date(scope.row.created_at).toLocaleString($companyConfiguration.helpers.getDateLocale()) }}
          </template>
        </el-table-column>
        <el-table-column label="Состояние" align="center">
          <template slot-scope="scope">
            {{ $companyConfiguration.settings.getEnum('back_integration_statuses')[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column label="Ошибок" align="center">
          <template slot-scope="scope">
            {{scope.row.result.errors_count ? scope.row.result.errors_count : 'Нет данных'}}
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template slot-scope="scope">
            <el-button
              style="margin-left:40px"
              size="mini"
              @click="showDetails(scope.row)"
            >
              Подробности
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <paginator
        :paginator.sync="paginator"
        @page-changed="getIntegrations"
      ></paginator>
    </div>

    <el-dialog
      destroy-on-close
      title="Подробности"
      :visible.sync="showDialog"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form
        size="small"
        label-position="left"
        label-width="200px"
      >
        <el-form-item label="TaskID">
          {{currentIntegration.task_id}}
        </el-form-item>
        <el-form-item label="Инициатор">
          {{currentIntegration.author ? currentIntegration.author.full_name : 'Нет данных'}}
        </el-form-item>
        <el-form-item label="Дата запуска">
          {{currentIntegration.created_at}}
        </el-form-item>
        <el-form-item label="Дата обновления">
          {{currentIntegration.updated_at}}
        </el-form-item>
        <el-form-item label="Состояние">
          {{ $companyConfiguration.settings.getEnum('back_integration_statuses')[currentIntegration.status] }}
        </el-form-item>
        <el-form-item label="Карт отправлено">
          {{currentIntegration.card_ids ? currentIntegration.card_ids.length : 'Нет данных'}}
        </el-form-item>
        <el-form-item label="Карт обработано">
          {{currentIntegration.result && currentIntegration.result.total_rows ? currentIntegration.result.total_rows : 'Нет данных'}}
        </el-form-item>
        <el-form-item label="Карт с ошибками">
          {{currentIntegration.result && currentIntegration.result.errors_count ? currentIntegration.result.errors_count : 'Нет данных'}}
        </el-form-item>
        <el-form-item label="Ошибки">
          <div v-if="currentIntegration.result && currentIntegration.result.errors">
            <div
              v-for="error in currentIntegration.result.errors"
            >
              {{error}}
            </div>
          </div>
          <div v-else>Нет данных</div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import HomeNavigation from "@/components/home/HomeNavigation";
import {mapGetters} from "vuex";
import HomeCharts from "@/componentsCompany/cherkizovo/home/HomeCharts.vue";
import PageHeading from "@/components/PageHeading.vue";
import requestSender from "@/api/base/requestSender";
import CommentsBlock from "@/components/comments/CommentsBlock.vue";
import {paginator} from "@/mixins/paginator";
import {sorter} from "@/mixins/sorter";
import Paginator from "@/components/Paginator.vue";
import UsersTable from "@/components/user/UsersTable.vue";
import EntitySavedReportForm from "@/components/report/builder/EntitySavedReportForm.vue";
import PeriodSelector from "@/components/PeriodSelector.vue";

export default {
  name: 'Integration',
  components: {PeriodSelector,
    EntitySavedReportForm, UsersTable, Paginator, CommentsBlock, PageHeading, HomeCharts, HomeNavigation},
  mixins:[paginator, sorter],

  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      loading: false,
      integrations: [],

      showDialog: false,
      currentIntegration: {},
    }
  },
  mounted() {
    this.getIntegrations();
  },
  methods: {
    getIntegrations(){
      this.loading = true;
      requestSender('get', 'cherkizovo-back-integration/get-integrations', {
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator, sorter}) => {
          this.sorterFill(sorter);
          this.paginatorFill(paginator);

          this.integrations = data.integrations;
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    showDetails(integration){
      this.currentIntegration = integration;
      this.showDialog = true;
    },
  }
}

</script>

<style lang="scss">

</style>